<template>
<div class="rezultat">
    <h2>{{ rank }}</h2>
    <p>Reaction time: {{ score }} ms</p>
</div>    
</template>


<script>

export default {
    props: ['score'],
    data() {
        return {
            rank: null,
        }
    },
    mounted() {
        if (this.score < 350) {
            this.rank = 'Ninja Fingers';
        } else if (this.score < 600) {
            this.rank = 'Rapid Reflexes';
        } else {
            this.rank = 'Snail pace...'
        }
    }
}
</script>


<style scoped>
    .rezultat {
        margin-top: 60px;
        background: rgb(250, 238, 170);
        color: orangered;
        padding : 10px 0;
    }
</style>